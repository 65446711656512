@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Lato&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,700;1,900&display=swap");

.ReactModal__Content.ReactModal__Content--after-open {
  border-radius: 30px !important;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 10 !important;
}
input[type="text"]::placeholder {
  opacity: 0.5 !important;
}

/* For modern browsers */
::placeholder {
  opacity: 0.5 !important;
}
.button-background {
  background: transparent linear-gradient(180deg, #c53031 0%, #631819 100%) 0%
    0% no-repeat padding-box;
}
::-webkit-scrollbar-thumb {
  background: #f14d47c9 !important; 
  border-radius: 20px;
  width: 10px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #f14d47 !important; 
}
body{
  overflow: hidden;
}
