@import url("https://fonts.googleapis.com/css2?family=Lato&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anek+Tamil:wght@100;200;300;400;500;600;700;800&family=Lato&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,700;1,900&display=swap");
body {
  margin: 0;
  font-family: "Anek Tamil", sans-serif;
  font-family: "Lato", sans-serif;
  font-family: "Poppins", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.react-switch-bg{
  background: #c53031 !important;
}
.video-modal{
  position: absolute;
  inset: 40px;
  border: 1px solid rgb(204, 204, 204);
  background: rgba(0, 0, 0, 0.555);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}